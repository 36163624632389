import request from '@/utils/service/request'

// 查询各个学校的人数
const listSchoolCountVo = (params) => {
  return request.get('/home/listSchoolCountVo', { params })
}
// 按根据专业查询学生数量
const listMajorStudentVo = (params) => {
  return request.get('/home/listMajorStudentVo', { params })
}
// 按月显示
const listMonthSchoolStudentVo = (params) => {
  return request.get('/home/listMonthSchoolStudentVo', { params })
}
// 按年显示
const listYearSchoolStudentVo = (params) => {
  return request.get('/home/listYearSchoolStudentVo', { params })
}

// 查询推广员人数
const listPromoterCountVo = (params) => {
  return request.get('/home/listPromoterCountVo', { params })
}

// 分页查询推广员人数
const pagePromoter = (params) => {
  return request.get('/home/pagePromoter', { params })
}

// 推广员人数
const pagePromoterCountVo = (params) => {
  return request.get('/home/pagePromoterCountVo', { params })
}
// 按月显示
const listMonthStudentVo = (params) => {
  return request.get('/home/listMonthStudentVo', { params })
}
// 按年显示
const listYearStudentVo = (params) => {
  return request.get('/home/listYearStudentVo', { params })
}
export {
  listSchoolCountVo,
  listMajorStudentVo,
  listMonthSchoolStudentVo,
  listYearSchoolStudentVo,
  listPromoterCountVo,
  pagePromoter,
  pagePromoterCountVo,
  listMonthStudentVo,
  listYearStudentVo

}
