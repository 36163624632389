import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import component from '@/components/common'
import './assets/scss/index.scss'
import './assets/iconfont/iconfont/iconfont.css'
import './assets/iconfont/fontAwesome/font-awesome.css'
import './plugins/element.js'
// import './mock/mock'
import './router/beforeEach'
import permission from './directive/permission/index'
Vue.prototype.$base_url = 'http://192.168.31.62:8081'
// Vue.prototype.$uploadHttp = 'http://61.143.13.254:8010/api/file/upload'
Vue.prototype.$uploadHttp = 'https://api.gdnandaedu.com/api/file/upload'
Vue.prototype.$fileHttp = 'https://update-api.cx-online.net'
// Vue.prototype.$fileHttp = 'https://api.gdnandaedu.com:8010/upload/image/'
Vue.config.productionTip = false

Vue.prototype.$moment = moment
moment.locale('zh-cn')
// 定义全局时间戳过滤器
Vue.filter('formatDate', (value) => moment(value).format('YYYY-MM-DD'))
Vue.filter('momentFormater', (dateStr, defaultFormat = 'YYYY-MM-DD HH:mm:ss') => moment(dateStr).format(defaultFormat))

// 定义手机号加星号过滤器
Vue.filter('hidePhone', (phone) => {
  if (!phone) return ''
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
})

Vue.use(component)
/* 全局自定义指令 */
Vue.use(permission)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
