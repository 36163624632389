<template>
  <div class="page-coantainer">
    <el-dialog
      :title="title"
      :visible.sync="kfProps.isOpen"
      width="60%"
      :before-close="handleClose"
    >
      <div>
        <el-form ref="form" :model="form" :inline="true" label-width="120px">
          <h3>报名信息</h3>
          <el-form-item label="学校">
            <el-input disabled v-model="init.schoolName" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input disabled v-model="init.majorName" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="专业层次">
            <el-select v-model="form.majorType" placeholder="请选择专业层次" style="width: 200px;">
              <span v-for="(item, index) in init.majorType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="学费来源">
            <el-select v-model="form.schoolingFrom" placeholder="请选择学费来源" style="width: 200px;">
              <span v-for="(item, index) in init.schoolingFrom" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="学费金额">
            <el-input disabled v-model="init.money" style="width: 200px;"></el-input>
          </el-form-item>
          <h3>个人信息</h3>
          <el-form-item label="姓名">
            <el-input v-model="form.name" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="form.sex" placeholder="请选择性别" style="width: 200px;">
              <span v-for="(item, index) in init.sex" :key="index">
                <el-option :label="item.name" :value="item.value"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="民族">
            <el-select v-model="form.nation" placeholder="请选择民族" style="width: 200px;">
              <span v-for="(item, index) in init.nation" :key="index">
                <el-option :label="item.text" :value="item.value"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="政治面貌">
            <el-select v-model="form.politicalOutlook" placeholder="请选择政治面貌" style="width: 200px;">
              <span v-for="(item, index) in init.politicalOutlook" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="证件类型">
            <el-select v-model="form.cardType" placeholder="请选择证件类型" style="width: 200px;">
              <span v-for="(item, index) in init.cardType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码">
            <el-input v-model="form.idCard" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="出生日期">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.birthday" style="width: 200px;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="婚姻状况">
            <el-select v-model="form.merryType" placeholder="请选择婚姻状况" style="width: 200px;">
              <span v-for="(item, index) in init.merryType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="学生类型">
            <el-select v-model="form.examType" placeholder="请选择学生类型" style="width: 200px;">
              <span v-for="(item, index) in init.examType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="学制">
            <el-select v-model="form.studyYear" placeholder="请选择学制" style="width: 200px;">
              <span v-for="(item, index) in init.studyYear" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="分布情况">
            <el-select v-model="form.distPlace" placeholder="请选择分布情况" style="width: 200px;">
              <span v-for="(item, index) in init.distPlace" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="在职状况">
            <el-select v-model="form.emerContactUnit" placeholder="请选择在职状况" style="width: 200px;">
              <span v-for="(item, index) in init.emerContactUnit" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <h3>户口信息</h3>
          <el-form-item label="户口性质">
            <el-select v-model="form.registerResidenceType" placeholder="请选择" style="width: 200px;">
              <span v-for="(item, index) in init.registerResidenceType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="籍贯(省)">
            <el-input v-model="form.registerResidenceProvince" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="籍贯(市县)">
            <el-input v-model="form.registerResidenceCity" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="户口所在地">
            <el-input v-model="form.registerResidence" style="width: 200px;"></el-input>
          </el-form-item>
          <h3>学历信息</h3>
          <el-form-item label="原学历姓名">
            <el-input v-model="form.preEduPersonalName" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="原学历证件类型">
            <el-select v-model="form.preEduPersonalCardType" placeholder="请选择" style="width: 200px;">
              <span v-for="(item, index) in init.preEduPersonalCardType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="原学历证件号码">
            <el-input v-model="form.preEduPersonalCardNumber" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="是否电大毕业">
            <el-select v-model="form.localGradFlag" placeholder="请选择" style="width: 200px;">
              <span v-for="(item, index) in init.localGradFlag" :key="index">
                <el-option :label="item.text" :value="item.value"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="原学历层次">
            <el-select v-model="form.preExamEdu" placeholder="请选择" style="width: 200px;">
              <span v-for="(item, index) in init.preExamEdu" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="毕业时间">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.gradTime" style="width: 200px;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="原学历学习类型">
            <el-select v-model="form.preEduType" placeholder="请选择" style="width: 200px;">
              <span v-for="(item, index) in init.preEduType" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="原学历所学专业">
            <el-input v-model="form.majorNameText" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="原学历毕业证书编号">
            <el-input v-model="form.gradCardNum" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="原学历证明材料">
            <el-select v-model="form.preEduCert" placeholder="请选择" style="width: 200px;">
              <span v-for="(item, index) in init.preEduCert" :key="index">
                <el-option :label="item" :value="item"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="证明材料编号">
            <el-input v-model="form.preEduCertNumber" style="width: 200px;"></el-input>
          </el-form-item>
          <h3>联系信息</h3>
          <el-form-item label="手机号码">
            <el-input v-model="form.phone" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="form.email" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="邮编">
            <el-input v-model="form.postcode" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="通讯地址">
            <el-input v-model="form.address" style="width: 200px;"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updateStudent">确 定</el-button>
        <!-- <el-button type="primary" @click="exportOneStuInfo">导 出</el-button> -->
        <!-- <el-button type="primary" @click="printSignForm">打 印</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStudentInfoById, updateStudent } from '@/api/api'
import { exportWordService } from '@/api/excel'
import {
  sex,
  politicalOutlook,
  idType,
  professionalLevel,
  studentType,
  employmentStatus,
  educationalSystem,
  distribution,
  maritalStatus,
  sourceFees,
  household,
  openUniversity,
  educationalLevel,
  learningType,
  evidence,
  educationalCert
} from '../../../utils/picker-data'
import nation from '../../../utils/nation'

export default {
  components: {

  },
  props: {
    kfProps: {
      type: Object
    }
  },
  data () {
    return {
      TEMPLATE_HTML_URL: `${process.env.BASE_URL}print/index.html`,
      isOpenDialog: false,
      title: '',
      init: {
        majorType: professionalLevel, // 专业层次
        schoolingFrom: sourceFees, // 学费来源
        sex: sex, // 性别
        nation: nation, // 民族
        politicalOutlook: politicalOutlook, // 政治面貌
        cardType: idType, // 证件类型
        merryType: maritalStatus, // 婚姻状况
        examType: studentType, // 学生类型
        studyYear: educationalSystem, // 学制
        distPlace: distribution, // 分布情况
        emerContactUnit: employmentStatus, // 在职状况
        registerResidenceType: household, // 户口性质
        preEduPersonalCardType: educationalCert, // 原学历证件类型
        localGradFlag: openUniversity, // 是否电大毕业
        preExamEdu: educationalLevel, // 原学历层次
        preEduType: learningType, // 原学历学习类型
        preEduCert: evidence, // 原学历证明材料
        schoolName: '', // 学校
        majorName: '', // 专业
        money: null
      },
      form: {
        promoterId: '', // 推荐人id
        applySchoolId: '', // 报考学校id
        applyMajorId: '', // 报考专业id
        wxOpenid: '', // 微信openid
        schoolType: 2, // 学校类型
        majorType: '', // 专业层次
        schoolingFrom: '', // 学费来源
        schoolingFee: null, // 学费金额
        name: '', // 姓名
        sex: null, // 性别
        nation: '', // 民族
        politicalOutlook: '', // 政治面貌
        cardType: '', // 证件类型
        idCard: '', // 证件号码
        birthday: '', // 出生日期
        merryType: '', // 婚姻状况
        examType: '', // 学生类型
        studyYear: '', // 学制
        distPlace: '', // 分布情况
        emerContactUnit: '', // 在职状况
        registerResidenceType: '', // 户口性质
        registerResidenceProvince: '', // 籍贯（省）
        registerResidenceCity: '', // 籍贯（市/区）
        registerResidence: '', // 户口所在地
        preEduPersonalName: '', // 原学历姓名
        preEduPersonalCardType: '', // 原学历证件类型
        preEduPersonalCardNumber: '', // 原学历证件号码
        localGradFlag: 0, // 是否电大毕业
        preExamEdu: '', // 原学历层次
        gradTime: '', // 毕业时间
        preEduType: '', // 原学历学习类型
        majorNameText: '', // 原学历所学专业
        gradCardNum: '', // 原学历毕业证书编号
        preEduCert: '', // 原学历证明材料
        preEduCertNumber: '', // 证明材料编号
        phone: '', // 手机号码
        email: '', // 邮箱
        postcode: '', // 邮编
        address: '' // 通讯地址
      }
    }
  },
  watch: {
    'kfProps.isOpen' (data) {
      if (data) {
        this.title = `推广员：${this.kfProps.promoterName}，报名学生：${this.kfProps.name}，报名时间：${this.kfProps.gmtCreate}`
        this.init.schoolName = this.kfProps.applySchoolName
        this.init.majorName = this.kfProps.applyMajorName
        this.init.money = this.kfProps.schoolingFee
        this.getStudentInfoById(this.kfProps.id)
      }
    }
  },
  methods: {
    /* 根据id获取学生信息 */
    getStudentInfoById (id) {
      getStudentInfoById(id).then(res => {
        this.form = res.data
        this.form.phone = this.form.phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
      })
    },

    /* 更新学生信息 */
    updateStudent () {
      updateStudent(this.form).then((res) => {
        this.$message({
          message: '更新成功',
          type: 'success'
        })
        this.updateSuccess()
      })
    },

    /* 更新成功 */
    updateSuccess () {
      this.$emit('closeDialog', { isOpen: false, type: 'success' })
    },

    /* 打印报名表单 */
    printSignForm () {
      const report = document.querySelector('#sign-body')
      const iframe = document.createElement('iframe')
      const iframeStyle =
        'border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;'
      iframe.style.cssText = iframeStyle
      const id = new Date().getTime()
      iframe.id = id
      iframe.src = this.TEMPLATE_HTML_URL
      document.body.appendChild(iframe)
      iframe.onload = () => {
        iframe.contentDocument.querySelector(
          '#signForm'
        ).innerHTML = this.nodeToString(report)
        setTimeout(() => {
          iframe.contentWindow.print()
        }, 2000)
        setTimeout(() => {
          iframe.remove()
        }, 2000)
      }
    },

    // 导出学生信息为word文档
    exportOneStuInfo () {
      const id = this.formData.id
      const name = this.formData.name
      exportWordService.exportOneStuInfo(id, name)
    },

    nodeToString (node) {
      let tmpNode = document.createElement('div')
      tmpNode.appendChild(node.cloneNode(true))
      const str = tmpNode.innerHTML
      tmpNode = node = null
      return str
    },

    // 关闭弹窗
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
