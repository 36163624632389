export default [{
  id: '01',
  text: '汉',
  value: 'Han'
},
{
  id: '02',
  text: '壮',
  value: 'Zhuang'
},
{
  id: '03',
  text: '回',
  value: 'Hui'
},
{
  id: '04',
  text: '藏',
  value: 'Tibetan'
},
{
  id: '05',
  text: '维吾尔',
  value: 'Uighur '
},
{
  id: '06',
  text: '苗',
  value: 'Miao'
},
{
  id: '07',
  text: '彝',
  value: 'Yi'
},
{
  id: '08',
  text: '蒙古',
  value: 'Mongol'
},
{
  id: '09',
  text: '布依',
  value: 'Buyi'
},
{
  id: '10',
  text: '朝鲜',
  value: 'Korean'
},
{
  id: '11',
  text: '满',
  value: 'Manchu'
},
{
  id: '12',
  text: '侗',
  value: 'Dong'
},
{
  id: '13',
  text: '瑶',
  value: 'Yao'
},
{
  id: '14',
  text: '白',
  value: 'Bai'
},
{
  id: '15',
  text: '土家',
  value: 'Tujia'
},
{
  id: '16',
  text: '哈尼',
  value: 'Hani'
},
{
  id: '17',
  text: '哈萨克',
  value: 'Kazakh'
},
{
  id: '18',
  text: '傣',
  value: 'Dai'
},
{
  id: '19',
  text: '黎',
  value: 'Li'
},
{
  id: '20',
  text: '傈僳',
  value: 'Lisu'
},
{
  id: '21',
  text: '佤',
  value: 'Wa'
},
{
  id: '22',
  text: '畲',
  value: 'She'
},
{
  id: '23',
  text: '高山',
  value: 'Gaoshan'
},
{
  id: '24',
  text: '拉祜',
  value: 'Lahu'
},
{
  id: '25',
  text: '水',
  value: 'Shui'
},
{
  id: '26',
  text: '东乡',
  value: 'Dongxiang'
},
{
  id: '27',
  text: '纳西',
  value: 'Naxi'
},
{
  id: '28',
  text: '景颇',
  value: 'Jingpo'
},
{
  id: '29',
  text: '柯尔克孜',
  value: 'Kirghiz'
},
{
  id: '30',
  text: '土',
  value: 'Tu'
},
{
  id: '31',
  text: '达斡尔',
  value: 'Daur'
},
{
  id: '32',
  text: '仫佬',
  value: 'Mulam'
},
{
  id: '33',
  text: '羌',
  value: 'Qiang'
},
{
  id: '34',
  text: '布朗',
  value: 'Blang'
},
{
  id: '35',
  text: '撒拉',
  value: 'Salar'
},
{
  id: '36',
  text: '毛南',
  value: 'Maonan'
},
{
  id: '37',
  text: '仡佬',
  value: 'Gelao'
},
{
  id: '38',
  text: '锡伯',
  value: 'Xibe'
},
{
  id: '39',
  text: '阿昌',
  value: 'Achang'
},
{
  id: '40',
  text: '普米',
  value: 'Pumi'
},
{
  id: '41',
  text: '塔吉克',
  value: 'Tajik'
},
{
  id: '42',
  text: '怒',
  value: 'Nu'
},
{
  id: '43',
  text: '乌孜别克',
  value: 'Uzbek'
},
{
  id: '44',
  text: '俄罗斯',
  value: 'Russian'
},
{
  id: '45',
  text: '鄂温克',
  value: 'Evenki'
},
{
  id: '46',
  text: '崩龙(得昂)',
  value: 'Bumloon'
},
{
  id: '47',
  text: '保安',
  value: 'Bonan'
},
{
  id: '48',
  text: '裕固',
  value: 'Yugur'
},
{
  id: '49',
  text: '京',
  value: 'Gin'
},
{
  id: '50',
  text: '塔塔尔',
  value: 'Tatar'
},
{
  id: '51',
  text: '独龙',
  value: 'Drung'
},
{
  id: '52',
  text: '鄂伦春',
  value: 'Oroqin'
},
{
  id: '53',
  text: '赫哲',
  value: 'Hezhen'
},
{
  id: '54',
  text: '门巴',
  value: 'Menba'
},
{
  id: '55',
  text: '珞巴',
  value: 'Lhobo'
},
{
  id: '56',
  text: '基诺',
  value: 'Jino'
}
]
