<template>
  <div>
    <div id="payMount" style="width: 100%;height:570px;"></div>
  </div>
</template>

<script>
import { listSchoolCountVo } from '@/api/homeChart'
import * as echarts from 'echarts'

export default {
  props: {
    queryInfo: {
      startTime: String,
      endTime: String
    }
  },
  mounted () {
    this.listSchoolCountVo()
  },
  data () {
    return {
      dataList: [],
      schDataList: []
    }
  },
  watch: {
    'queryInfo.startTime' () {
      this.listSchoolCountVo()
    },
    'queryInfo.endTime' () {
      this.listSchoolCountVo()
    }
  },
  methods: {
    listSchoolCountVo () {
      listSchoolCountVo(this.queryInfo).then(res => {
        const data = res.data
        this.schDataList = []
        data.forEach(item => {
          const schData = {}
          schData.name = item.schoolName
          schData.value = item.payStudentCount
          this.schDataList.push(schData)
        })
        const myEcharts = echarts.init(document.getElementById('payMount'))
        myEcharts.setOption({
          title: {
            text: '已支付人数',
            left: 'center'
          },
          legend: {
            top: 'bottom'
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: '学生数量',
              type: 'pie',
              radius: '40%',
              data: this.schDataList,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        })
      })
    }
  }
}
</script>
