const sex = [{
  name: '男',
  value: 1
}, {
  name: '女',
  value: 2
}]

// 政治面貌
const politicalOutlook = ['中共党员', '群众', '民盟盟员', '民进会员', '群众', '共青团员', '民革会员', '民建会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派民主人士']

// 证件类型
const idType = ['身份证', '军人证', '护照', '港澳台居民证件']

// 专业层次
const professionalLevel = ['专升本', '专科']

// 学生类型
const studentType = ['本科（专升本）', '专科']

// 在职状况
const employmentStatus = ['国家机关党群组织企事业单位负责人', '办事人员和有关人员', '商业工作人员', '服务性工作人员', '农林牧渔水利业人员', '工人', '待业人员', '学生']

// 学制
const educationalSystem = ['2.5', '3', '4']

// 分布情况
const distribution = ['省(市)', '地(市)', '县城']

// 婚姻状况
const maritalStatus = ['未婚', '已婚', '离婚', '丧偶', '其他']

// 学费来源
const sourceFees = ['自费']

// 户口性质
const household = ['农业', '非农业']

// 是否电大毕业
const openUniversity = [{
  text: '否',
  value: 0
}, {
  text: '是',
  value: 1
}]

// 原学历层次
const educationalLevel = ['职高中专技校等同等学历', '大专', '高中', '学士', '研究生', '硕士', '博士', '本科']

// 原学历学习类型
const learningType = ['自考', '成人高等教育', '电大（开放教育）', '普通高等教育', '网络教育']

// 原学历证明材料
const evidence = ['学历证书电子注册备案表在线验证码', '学历认证报告编码', '留学认证报告']

// 原学历证件类型
const educationalCert = ['居民身份证']

export {
  sex,
  politicalOutlook,
  idType,
  professionalLevel,
  studentType,
  employmentStatus,
  educationalSystem,
  distribution,
  maritalStatus,
  sourceFees,
  household,
  openUniversity,
  educationalLevel,
  learningType,
  evidence,
  educationalCert
}
