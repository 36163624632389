<template>
  <el-dialog
      :title="salesStudentData.promoterName"
      :visible.sync="salesStudentData.isOpen"
      width="1200px"
      :before-close="closeHandler"
      center
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-radio-group v-model="dateSelect" @change="showMonthHandler">
          <el-radio-button label="按月显示"></el-radio-button>
          <el-radio-button label="按日显示"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="12">
        <el-date-picker
            v-if="!showMonth"
            v-model="year"
            type="year"
            value-format="yyyy"
            placeholder="请选择年"
            @change="listYearStudentVo">
        </el-date-picker>
      </el-col>
      <el-col :span="12">
        <el-date-picker
            v-if="showMonth"
            v-model="month"
            type="month"
            placeholder="请选择月份"
            @change="listMonthStudentVo">
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24" v-if="!showMonth">
        <div id="echarts-box4" style="width: 1200px;height: 600px;"></div>
      </el-col>
      <el-col :span="24" v-if="showMonth">
        <div id="echarts-box5" style="width: 1200px;height: 600px;"></div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>

import { listMonthStudentVo, listYearStudentVo } from '@/api/homeChart'
import moment from 'moment/moment'
import * as echarts from 'echarts'

export default {
  props: {
    salesStudentData: {
      promoterName: String,
      isOpen: Boolean,
      id: String
    }
  },
  data () {
    return {
      dateSelect: '按月显示',
      showMonth: false,
      year: new Date(),
      month: new Date(),
      monthList: [],
      monthStudentList: [],
      dayList: [],
      dayStudentList: []
    }
  },
  watch: {
    'salesStudentData.isOpen' () {
      if (this.salesStudentData.isOpen) {
        this.listYearStudentVo()
      }
    }
  },
  methods: {
    showMonthHandler (val) {
      if (val === '按日显示') {
        this.showMonth = true
        this.listMonthStudentVo()
      } else {
        this.showMonth = false
        this.listYearStudentVo()
      }
    },
    listYearStudentVo () {
      listYearStudentVo({
        promoterId: this.salesStudentData.id,
        year: moment(this.year).format('yyyy')
      }).then(res => {
        const data = res.data
        this.monthList = []
        this.monthStudentList = []
        data.forEach(item => {
          this.monthList.push(item.month)
          this.monthStudentList.push(item.studentCount)
        })
        const myEcharts4 = echarts.init(document.getElementById('echarts-box4'))
        myEcharts4.setOption({
          title: {
            text: this.salesStudentData.promoterName
          },
          xAxis: {
            type: 'category',
            data: this.monthList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '学生数量',
              data: this.monthStudentList,
              type: 'line',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              },
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
        })
      })
    },
    listMonthStudentVo () {
      listMonthStudentVo({
        promoterId: this.salesStudentData.id,
        month: moment(this.month).format('yyyy-MM')
      }).then(res => {
        const data = res.data
        this.dayList = []
        this.dayStudentList = []
        data.forEach(item => {
          this.dayList.push(item.day)
          this.dayStudentList.push(item.studentCount)
        })
        const myEcharts5 = echarts.init(document.getElementById('echarts-box5'))
        myEcharts5.setOption({
          title: {
            text: this.salesStudentData.promoterName
          },
          xAxis: {
            type: 'category',
            axisLabel:
                {
                  show: true,
                  interval: 0, // 使x轴上的文字显示完全,
                  // 设置一行显示几个字,自己设置
                  formatter: function (params) {
                    var newParamsName = ''
                    var paramsNameNumber = params.length
                    var provideNumber = 5
                    var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                    if (paramsNameNumber > provideNumber) {
                      for (var p = 0; p < rowNumber; p++) {
                        var tempStr = ''
                        var start = p * provideNumber
                        var end = start + provideNumber
                        if (p === rowNumber - 1) {
                          tempStr = params.substring(start, paramsNameNumber)
                        } else {
                          tempStr = params.substring(start, end) + '\n'
                        }
                        newParamsName += tempStr
                      }
                    } else {
                      newParamsName = params
                    }
                    return newParamsName
                  }
                },
            data: this.dayList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '学生数量',
              data: this.dayStudentList,
              type: 'bar',
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
        })
      })
    },
    closeHandler () {
      this.dateSelect = '按月显示'
      this.showMonth = false
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style scoped>

</style>
